
async function getLiveData() {
    // "10:30 AM", "09:30:00"
    // "12:01 PM", "11:00:00"
    // "02:30 PM", "13:30:00"
    // "04:30 PM", "15:00:00"
    // Parse.serverURL = 'https://parseapi.back4app.com';
    // Parse.keyLiveQueryUrl = 'wss://Lucky2D.b4a.io';
    // Parse.initialize('dxEhlPEJK3rGaa1viywMIxS31lqCFZMwb0oHQWXJ', 'G4ePnxxZcdObpoF8bZMx2QzvgNlFrpGb8WHrF0Bx');
    // var client = new Parse.LiveQueryClient({
    //     applicationId: 'dxEhlPEJK3rGaa1viywMIxS31lqCFZMwb0oHQWXJ',
    //     serverURL: 'wss://Lucky2D.b4a.io',
    //     javascriptKey: 'G4ePnxxZcdObpoF8bZMx2QzvgNlFrpGb8WHrF0Bx'
    // });

    Parse.serverURL = 'https://parseapi.back4app.com';
    Parse.keyLiveQueryUrl = 'wss://happy7788.b4a.io';
    Parse.initialize('4M259VpbdjqPJ46U7EkCH0R8Qt4awj9sAci56ony', 'MyWd0ieW0bK5YQEbdzvhGdKh9JVJc5DBmXlEh04j');
    var client = new Parse.LiveQueryClient({
        applicationId: '4M259VpbdjqPJ46U7EkCH0R8Qt4awj9sAci56ony',
        serverURL: 'wss://happy7788.b4a.io',
        javascriptKey: 'MyWd0ieW0bK5YQEbdzvhGdKh9JVJc5DBmXlEh04j'
    });

    client.open();

    var query = new Parse.Query('TwoDLiveResult');

    var datas = await query.get("jJVVrKYHFl");

    if (datas != '' && datas != null && datas != undefined) {
        document.getElementById('result').innerHTML = datas.get('result');
        var date = new Date(datas.get('updatedAt').toString());
        var dateStr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        var timeStr = date.toLocaleTimeString();
        var updatedTime = "Updated: " + dateStr + " " + timeStr;


        var showAnimation = false;

        var sectionDatas = datas.get('data');
        console.log(sectionDatas)
        if (sectionDatas != null && sectionDatas != "") {

            document.getElementById('section-container').innerHTML = '<div id="section-container" class="container"> </div>';
            var sectionList = JSON.parse(sectionDatas);
            console.log(sectionList);
            for (let i = 0; i < sectionList.length; i++) {
                var item = sectionList[i];
                var from = new Date(item.fromDateTime);
                var to = new Date(item.toDateTime);
                var now = new Date();

                if ((from.getTime() < now.getTime() && to.getTime() > now.getTime() && !item.isDone) ||
                    (from.getTime() == now.getTime() && !item.isDone) ||
                    (to.getTime() == now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                    (to.getTime() <= now.getTime() && (item.section == "12:01 PM" && !item.isDone))
                ) {
                    // console.warn('heeloo111')
                    if (item.isDone == false) {
                        var date = new Date(datas.get('updatedAt').toString());
                        var dateStr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                        var timeStr = (date.getHours()>12?date.getHours()-12:date.getHours())+":"+(date.getMinutes()>10?date.getMinutes():"0"+date.getMinutes())+":"+(date.getSeconds()>10?date.getSeconds():"0"+date.getSeconds())+(date.getHours()>12?" PM":" AM");
                        // var timeStr="10:28:00 AM";
                        if (timeStr >= '10:30:00 AM' && timeStr <="10:32:00 AM") {
                            console.warn('10:30:00 AM')
                            timeStr = '10:30:00 AM';
                        }
                        else if (timeStr >= '12:01:00 AM' && timeStr <= '12:03:00 AM' ) {
                            console.warn('12:01:00 PM')
                            timeStr = '12:01:00 PM';
                        }
                        else if (timeStr >= '02:30:00 PM' && timeStr <= '02:32:00 PM' ) {
                            console.warn('02:30:00 PM')
                            timeStr = '02:30:00 PM';
                        }
                        else if (timeStr >= '04:30:00 PM' && timeStr <= '04:32:00 AM' ) {
                            console.warn('04:30:00 PM')
                            timeStr = '04:30:00 PM';
                        }
                        var updatedTime = "Updated: " + dateStr + " " + timeStr;
                    }

                    showAnimation = true;
                    break;
                }
                else {
                    console.warn('heeloo222')
                    if (item.isDone) {
                        var date = new Date(item.toDisplayDateTime);
                        var dateStr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                        var timeStr = date.toLocaleTimeString();
                        var timeStr = (date.getHours()>12?date.getHours()-12:date.getHours())+":"+(date.getMinutes()>10?date.getMinutes():"0"+date.getMinutes())+":"+(date.getSeconds()>10?date.getSeconds():"0"+date.getSeconds())+(date.getHours()>12?" PM":" AM");
                        var updatedTime = "Updated: " + dateStr + " " + timeStr;

                    }

                    showAnimation = false;
                }
            }

            if (showAnimation == false) {
                document.getElementById('result').className = 'resultStyle';
            }
            else {
                document.getElementById('result').className = 'resultStyleAnimate';
            }
            console.log('reachhdfd', updatedTime)
            document.getElementById('lastUpdateDate').innerHTML =
                (showAnimation == false ? "<ion-icon name='checkmark-outline' style='font-size:15px;margin-right:4px;'></ion-icon>" : "<ion-icon name='reload-outline' style='font-size:15px;margin-right:4px;'></ion-icon>")
                + '<span>' + updatedTime + '</span>';
            sectionList.forEach((item, index) => {
                console.log(item)
                var section = item.section;
                var set0 = '--';
                var set1 = '';

                if (item.set != "--" && item.set != null && item.set != '') {
                    set0 = item.set.substr(0, item.set.length - 1);

                    set1 = item.set.substr(item.set.length - 1, item.set.length);

                }

                var val0 = '--';
                var val1 = '';
                var val2 = '';
                var result = '--';
                if (item.value != "--" && item.value != null && item.value != '') {
                    val0 = item.value.substr(0, item.value.length - 4);
                    val1 = item.value.substr(item.value.length - 4, 1);
                    val2 = item.value.substr(item.value.length - 3, item.value.length);
                }
                result = item.result;

                var now = new Date();
                var isShake = (new Date(item.fromDateTime).getTime() < now.getTime() &&
                    new Date(item.toDateTime).getTime() > now.getTime() && (item.section == "12:01 PM" && !item.isDone)) ||
                    (new Date(item.fromDateTime).getTime() < now.getTime() &&
                        new Date(item.toDateTime).getTime() > now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                    (new Date(item.fromDateTime).getTime() == now.getTime() && !item.isDone) ||
                    (new Date(item.toDateTime).getTime() == now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                    (new Date(item.toDateTime).getTime() <= now.getTime() && (item.section == "12:01 PM" && !item.isDone));

                document.getElementById('section-container').innerHTML = `${document.getElementById('section-container').innerHTML}
                <div class='row section-inner'>
                 
                    <a href='${((item.section == "12:01 PM" && item.isDone == true) || (item.section != "12:01 PM" && item.isDone == true)) ? `/d-page/twod-history/${section}` : 'javascript:'}' style = 'width: 100%; text-decoration: none; color: white;'>
                    <div class='container py-2'>                    
                        <div class='row d-flex justify-content-center ${index != 0 ? "pt-1" : ""}'> ${section} </div>
                        <div class='row' style='padding-top: 8px; text-align: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;'> 
                            <div class='col-4'><p style='color: #fff;'>Set</p></div>
                            <div class='col-4'><p style='color: #fff;'>Value</p></div>
                            <div class='col-3'><p style='color: #fff;'>2D</p></div>
                            <div class='col-1'></div>
                        </div >
                        <div class='row ${isShake ? 'annimate' : 'no-annimate'}'>
                        <div class='col-4'> <span>${set0}</span ><span style='color: #eabe3b;'>${set1}</span></div> 
                        <div class='col-4'><span>${val0}</span><span style='color: #eabe3b;'>${val1}</span><span>${val2}</span></div> 
                        <div class='col-3'><span style='color: #eabe3b;'>${isShake == true ? "--" : result}</span> </div> 
                        <div class='col-1'>${(((item.section == "12:01 PM" && item.isDone == true) || (item.section != "12:01 PM" && item.isDone == true)) ? "<ion-icon name='chevron-forward-outline' style='font-size:15px;'></ion-icon>" : "")}</div>
                        </div>                     
                        
                    </div>
                    </a>
                </div>`;
            });


        }
    }

    var subscription = client.subscribe(query);

    subscription.on('open', result => {
        console.error('open', result)
        /// console.log("OPENED"+JSON.stringify(result));
    });


    subscription.on('update', result => {
        // console.log("UPDATED" + result);
        var page = document.getElementById('livePage');

        // console.log("Page: " + page);
        if (page != null) {

            var datas = result;

            if (datas != '' && datas != null && datas != undefined) {

                document.getElementById('result').innerHTML = datas.get('result');

                var showAnimation = false;

                var sectionDatas = datas.get('data');
                if (sectionDatas != null && sectionDatas != "") {
                    document.getElementById('section-container').innerHTML = '<div id="section-container" class="container"> </div>';

                    var sectionList = JSON.parse(sectionDatas);

                    // console.log(sectionList);

                    for (let i = 0; i < sectionList.length; i++) {
                        var item = sectionList[i];
                        var from = new Date(item.fromDateTime);
                        var to = new Date(item.toDateTime);
                        var now = new Date();

                      

                        if ((from.getTime() < now.getTime() && to.getTime() > now.getTime() && !item.isDone) ||
                            (from.getTime() == now.getTime() && !item.isDone) ||
                            (to.getTime() == now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                            (to.getTime() <= now.getTime() && (item.section == "12:01 PM" && !item.isDone))
                        ) {
                            showAnimation = true;

                            if (item.isDone == false) {
                                var date = new Date(datas.get('updatedAt').toString());
                                var dateStr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                                var timeStr = (date.getHours()>12?(date.getHours()-12)>9?date.getHours()-12:'0'+(date.getHours()-12):date.getHours()>9?date.getHours():'0'+(date.getHours()))+":"+(date.getMinutes()>=10?date.getMinutes():"0"+date.getMinutes())+":"+(date.getSeconds()>=10?date.getSeconds():"0"+date.getSeconds())+(date.getHours()>12?" PM":" AM");
                                // console.log(date.getHours()+":"+date.getMinutes());
                                // console.log( timeStr)
                                if (timeStr >= '10:30:00 AM' && timeStr <="10:32:00 AM") {
                                    // console.warn('10:30:00 AM')
                                    timeStr = '10:30:00 AM';
                                }
                                else if (timeStr >= '12:01:00 PM' && timeStr <= '12:03:00 PM' ) {
                                    // console.warn('12:01:00 PM')
                                    timeStr = '12:01:00 PM';
                                }
                                else if (timeStr >= '02:30:00 PM' && timeStr <= '02:32:00 PM' ) {
                                    // console.warn('02:30:00 PM')
                                    timeStr = '02:30:00 PM';
                                }
                                else if (timeStr >= '04:30:00 PM' && timeStr <= '04:32:00 PM' ) {
                                    // console.warn('04:30:00 PM')
                                    timeStr = '04:30:00 PM';
                                }
                                var updatedTime = "Updated: " + dateStr + " " + timeStr;
                            }
                            break;
                        }
                        else {
                            if (item.isDone) {
                                var date = new Date(item.toDisplayDateTime);
                                var dateStr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                                var timeStr = date.toLocaleTimeString();
                                var updatedTime = "Updated: " + dateStr + " " + timeStr;
                                
                            }

                            showAnimation = false;
                        }
                    }

                    if (showAnimation == false) {

                        document.getElementById('result').className = 'resultStyle';
                    }
                    else {
                        document.getElementById('result').className = 'resultStyleAnimate';
                    }

                    document.getElementById('lastUpdateDate').innerHTML =
                        (showAnimation == false ? "<ion-icon name='checkmark-outline' style='font-size:15px;margin-right:4px;'></ion-icon>" : "<ion-icon name='reload-outline' style='font-size:15px;margin-right:4px;'></ion-icon>")
                        + '<span>' + updatedTime + '</span>';




                    sectionList.forEach((item, index) => {

                        var section = item.section;
                        var set0 = '--';
                        var set1 = '';

                        if (item.set != "--" && item.set != null && item.set != '') {
                            set0 = item.set.substr(0, item.set.length - 1);

                            set1 = item.set.substr(item.set.length - 1, item.set.length);

                        }

                        var val0 = '--';
                        var val1 = '';
                        var val2 = '';
                        var result = '--';
                        if (item.value != "--" && item.value != null && item.value != '') {
                            val0 = item.value.substr(0, item.value.length - 4);
                            val1 = item.value.substr(item.value.length - 4, 1);
                            val2 = item.value.substr(item.value.length - 3, item.value.length);
                        }


                        result = item.result;

                        var now = new Date();
                        // var isShake = (new Date(item.fromDateTime).getTime() < now.getTime() &&
                        //     new Date(item.toDateTime).getTime() > now.getTime() && !item.isDone) ||
                        //     (new Date(item.fromDateTime).getTime() == now.getTime() && !item.isDone) ||
                        //     (new Date(item.toDateTime).getTime() == now.getTime() && !item.isDone);

                        // document.getElementById('section-container').innerHTML = document.getElementById('section-container').innerHTML +
                        //     "<div class='row' style='text-align: center; - webkit - box - pack: justify; -ms - flex - pack: justify;justify - content: space - between;padding:0px;margin-top:8px;margin-bottom:8px;margin-left:0px;margin-right:0px;'>" +
                        //     "<a " + (item.isDone == true ? "href='/twod-history/" + section + "'" : "") +
                        //     "style = 'width: 100%;text-decoration:none;color:white; '> <div class='card' style='background-color:#000; margin: 2px 0px;padding: 10px;text - align: center;'><div style='text-align: center;color: white;font - weight: bold;padding - bottom: 8px;'>" +
                        //     section +
                        //     "</div> <div class='row' style='padding-top: 8px;text-align: center; - webkit - box - pack: justify;-ms - flex - pack: justify;justify - content: space - between;'> <div class='col-4'><p>Set</p></div><div class='col-4'><p>Value</p></div><div class='col-3'><p>2D</p></div><div class='col-1'></div></div >" +
                        //     "<div class='row' style='" + (isShake == true ? "-webkit-animation: fadeIn 1s infinite alternate;animation: fadeIn 1s infinite alternate;" : "") + "text - align: center; -webkit - box - pack: justify;-ms - flex - pack: justify;justify - content: space - between;'>" +
                        //     " <div class='col - 4'> <span >"
                        //     + set0 +
                        //     "</span ><span style='color: #eabe3b;'>" +
                        //     set1 + "</span></div> <div class='col - 4'> </span><span>" + val0 + "</span><span style='color: #eabe3b;'>" + val1 + "</span><span>" + val2 + "</span></div> <div class='col-3'><span style='color: #eabe3b;'>" + result +
                        //     "</span> </div> <div class='col-1'>" +
                        //     (item.isDone == false ? "" : "<ion-icon name='chevron-forward-outline' style='font-size:15px;'></ion-icon>") +
                        //     "</div ></div ></div > </a > </div > ";


                        var isShake = (new Date(item.fromDateTime).getTime() < now.getTime() &&
                            new Date(item.toDateTime).getTime() > now.getTime() && (item.section == "12:01 PM" && !item.isDone)) ||
                            (new Date(item.fromDateTime).getTime() < now.getTime() &&
                                new Date(item.toDateTime).getTime() > now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                            (new Date(item.fromDateTime).getTime() == now.getTime() && !item.isDone) ||
                            (new Date(item.toDateTime).getTime() == now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                            (new Date(item.toDateTime).getTime() <= now.getTime() && (item.section == "12:01 PM" && !item.isDone));
                        document.getElementById('section-container').innerHTML = `${document.getElementById('section-container').innerHTML}
                <div class='row section-inner'>
               
                    <a href='${((item.section == "12:01 PM" && item.isDone == true) || (item.section != "12:01 PM" && item.isDone == true)) ? `/d-page/twod-history/${section}` : 'javascript:'}' style = 'width: 100%; text-decoration: none; color: white;'>
                    <div class='container py-2'>  
                                   
                        <div class='row d-flex justify-content-center ${index != 0 ? "pt-1" : ""}'> ${section} </div>
                        <div class='row' style='padding-top: 8px; text-align: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;'> 
                            <div class='col-4'><p style='color: #fff;'>Set</p></div>
                            <div class='col-4'><p style='color: #fff;'>Value</p></div>
                            <div class='col-3'><p style='color: #fff;'>2D</p></div>
                            <div class='col-1'></div>
                        </div >
                        <div class='row ${isShake ? 'annimate' : 'no-annimate'}'>
                        <div class='col-4'> <span>${set0}</span ><span style='color: #eabe3b;'>${set1}</span></div> 
                        <div class='col-4'><span>${val0}</span><span style='color: #eabe3b;'>${val1}</span><span>${val2}</span></div> 
                        <div class='col-3'><span style='color: #eabe3b;'>${isShake == true ? "--" : result}</span> </div> 
                        <div class='col-1'>${(((item.section == "12:01 PM" && item.isDone == true) || (item.section != "12:01 PM" && item.isDone == true)) ? "<ion-icon name='chevron-forward-outline' style='font-size:15px;'></ion-icon>" : "")}</div>
                        </div>                     
                        
                    </div>
                    </a>
                </div>`;

                    });

                }
            }
        }

    });

}

