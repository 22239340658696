
async function getLiveDataforStream() {

    Parse.serverURL = 'https://parseapi.back4app.com';
    Parse.keyLiveQueryUrl = 'wss://happy7788.b4a.io';
    Parse.initialize('4M259VpbdjqPJ46U7EkCH0R8Qt4awj9sAci56ony', 'MyWd0ieW0bK5YQEbdzvhGdKh9JVJc5DBmXlEh04j');
    var client = new Parse.LiveQueryClient({
        applicationId: '4M259VpbdjqPJ46U7EkCH0R8Qt4awj9sAci56ony',
        serverURL: 'wss://happy7788.b4a.io',
        javascriptKey: 'MyWd0ieW0bK5YQEbdzvhGdKh9JVJc5DBmXlEh04j'
    });

    client.open();

    var query = new Parse.Query('TwoDLiveResult');

    var datas = await query.get("jJVVrKYHFl");

   
    if (datas != '' && datas != null && datas != undefined) {
        var sectionDatas = datas.get('data');
        if (sectionDatas != null && sectionDatas != "") {

            var sectionList = JSON.parse(sectionDatas);
            sectionList.forEach((item, index) => {

                var section = item.section;
                var set0 = '--';
                var set1 = '';
                var from = new Date(item.fromDateTime);
                var to = new Date(item.toDateTime);
                var now = new Date();
                if (item.set != "--" && item.set != null && item.set != '') {
                    set0 = item.set.substr(0, item.set.length - 1);

                    set1 = item.set.substr(item.set.length - 1, item.set.length);

                }


                var val0 = '--';
                var val1 = '';
                var val2 = '';
                var result = '--';
                if (item.value != "--" && item.value != null && item.value != '') {
                    val0 = item.value.substr(0, item.value.length - 4);
                    val1 = item.value.substr(item.value.length - 4, 1);
                    val2 = item.value.substr(item.value.length - 3, item.value.length);
                }
                console.log(from.getTime() < now.getTime())
                console.log(to.getTime() > now.getTime())
                console.log(to)
                if(( to.getTime() <= now.getTime()) && (item.isDone)) {
                    console.error('here testing warning');
                    document.getElementById('setId').innerHTML = `<span>${set0}</span ><span style='color: #eabe3b;'>${set1}</span>`;
                    document.getElementById('valueId').innerHTML = `<span>${val0}</span><span style='color: #eabe3b;'>${val1}</span><span>${val2}</span>`;
                }   

            });
        }
        document.getElementById('result').innerHTML = datas.get('result');
       
   
    }

    var subscription = client.subscribe(query);

    subscription.on('open', result => {
        /// console.log("OPENED"+JSON.stringify(result));
    });


    subscription.on('update', result => {
       // console.log("UPDATED" + result);
        var page = document.getElementById('livePage');

        console.log("Page: " + page);
        if (page != null) {
            var datas = result;

            if (datas != '' && datas != null && datas != undefined) {

                document.getElementById('result').innerHTML = datas.get('result');

                //var date = new Date(datas.get('updatedAt').toString());
                // var dateStr = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                // var timeStr = date.toLocaleTimeString();
                // var updatedTime = "Updated: " + dateStr + " " + timeStr;
                // console.log(new Date(datas.get('updatedAt').toString()))


                var showAnimation = false;

                var sectionDatas = datas.get('data');
                if (sectionDatas != null && sectionDatas != "") {

                    var sectionList = JSON.parse(sectionDatas);


                    for (let i = 0; i < sectionList.length; i++) {
                        var item = sectionList[i];
                        var from = new Date(item.fromDateTime);
                        var to = new Date(item.toDateTime);
                        var now = new Date();                        
                        if ((from.getTime() < now.getTime() && to.getTime() > now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                            (from.getTime() < now.getTime() && to.getTime() > now.getTime() && (item.section == "12:01 PM")) ||
                            (from.getTime() == now.getTime() && !item.isDone) ||
                            (to.getTime() == now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
                            (to.getTime() <= now.getTime() && (item.section == "12:01 PM"))
                        ) {
                            var set0 = '--';
                            var set1 = '';

                            if (item.set != "--" && item.set != null && item.set != '') {
                                set0 = item.set.substr(0, item.set.length - 1);

                                set1 = item.set.substr(item.set.length - 1, item.set.length);

                            }

                            var val0 = '--';
                            var val1 = '';
                            var val2 = '';
                            var result = '--';
                            if (item.value != "--" && item.value != null && item.value != '') {
                                val0 = item.value.substr(0, item.value.length - 4);
                                val1 = item.value.substr(item.value.length - 4, 1);
                                val2 = item.value.substr(item.value.length - 3, item.value.length);
                            }
                            document.getElementById('setId').innerHTML = `<span>${set0}</span ><span style='color: #eabe3b;'>${set1}</span>`;
                            document.getElementById('valueId').innerHTML = `<span>${val0}</span><span style='color: #eabe3b;'>${val1}</span><span>${val2}</span>`;
                            console.log('reach here')
                            showAnimation = true;
                            break;
                        }
                        else {
                            showAnimation = false;
                        }
                    }

                    if (showAnimation == false) {
                        // document.getElementById('setId').className="resultStyle";
                        // document.getElementById('valueId').className="resultStyle";
                        document.getElementById('result').className = 'resultStyle';
                    }
                    else {
                        // document.getElementById('setId').className="resultStyleAnimate";
                        // document.getElementById('valueId').className="resultStyleAnimate";
                        document.getElementById('result').className = 'resultStyleAnimate';
                    }
                }
            }
        }

    });

}

